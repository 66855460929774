export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')];

export const server_loads = [];

export const dictionary = {
	"(marketing)": [10,[3]],
	"(auth)/login": [6,[2]],
	"(auth)/signup": [8,[2]],
	"(marketing)/about": [11,[3]],
	"(marketing)/accessories": [12,[3]],
	"(marketing)/features": [13,[3]],
	"(marketing)/organizations": [14,[3]],
	"(marketing)/privacy": [15,[3]],
	"(marketing)/providers": [16,[3]],
	"(marketing)/reviews": [17,[3]],
	"(marketing)/subscribe": [18,[3]],
	"(marketing)/terms": [19,[3]],
	"checkout": [21,[4]],
	"payment": [22,[5]],
	"(auth)/password/reset": [7,[2]],
	"(auth)/terra/auth": [9,[2]],
	"payment/rx": [23,[5]],
	"[...path]": [20]
};